import { Card, Content, Div, ListDiv, ListText, Title, ListItemCustom, DoneIcon, PriceDiv, ListTextDescription } from './styles'
import { useContext } from 'react'
import { List } from '@material-ui/core'
import { QuotationContext } from '../../contexts/QuotationContextProvider'

function Success() {
    const { productQuotationData } = useContext(QuotationContext)

    return (
        <Content>
            <Title variant="h6">Cotação enviada com sucesso!</Title>

            <Card>
                <Title>Detalhes da Cotação</Title>
                <List>
                    <ListItemCustom>
                        {productQuotationData.map((product) => {
                            return (<ListDiv>
                                <Div>
                                    <ListText variant="body2">{product.quantidade}</ListText>
                                    <ListTextDescription variant="body2">{product.descricao}</ListTextDescription>
                                </Div>
                                <PriceDiv>
                                    <ListText variant="body2">{(product.price * product.quantidade).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</ListText>
                                </PriceDiv>
                            </ListDiv>)
                        })}
                    </ListItemCustom>
                    <ListDiv>
                        <ListText><strong>Total</strong></ListText>
                        <ListText><strong>{productQuotationData.reduce((accumulator, currentValue) => {
                            return accumulator += currentValue.price * currentValue.quantidade;
                        }, 0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                        }</strong></ListText>
                    </ListDiv>
                </List>
                <DoneIcon />
            </Card>
        </Content>
    )
}

export default Success
