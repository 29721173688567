import { theme } from './../../theme/theme';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@material-ui/core';
import { WarningRounded } from '@material-ui/icons';

export const ContainerError = styled(Box)<BoxProps>({
    height: 'calc(100vh - 64px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(4),
    color: theme.palette.text.secondary,
    flexDirection: 'column',
})

export const ContentError = styled(Box)<BoxProps>({
    maxWidth: 482,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4)
})

export const ContentMessage = styled(Box)<BoxProps>({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
})

export const Title = styled(Typography)<TypographyProps>({
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
})

export const Subtitle = styled(Typography)<TypographyProps>({
    fontSize: theme.spacing(1.5),
    fontWeight: 'bold',
})

export const WarningIcon = styled(WarningRounded)({
    fontSize: theme.spacing(8), 
    color: 'var(--red)',
})