import { Box, BoxProps, styled } from "@material-ui/core";
import { theme } from '../../theme/theme'

export const ModalContainer = styled(Box)<BoxProps>({
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 386,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    gap: theme.spacing(3),
    borderRadius: 4,
    transform: 'translate(-50%, -50%)',
})