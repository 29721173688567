import { ThemeProvider } from '@material-ui/core';
import PriceCotation from './pages/PriceQuotation';
import SignedQuotation from './pages/SignedQuotation';
import Error from './pages/Error';
import Header from './components/Header';
import Success from './pages/Success'
import { Container } from './App.styles'
import ClosedQuotation from './pages/ClosedQuotation'
import { theme } from './theme/theme'
import { QuotationContextProvider } from './contexts/QuotationContextProvider'
import Compose from './contexts/Compose'
import {
  Router,
  Switch,
  Route,
} from "react-router-dom";
import { createBrowserHistory } from 'history'
import { ErrorPage } from 'insidesistemas-error-pages/dist/pages/ErrorPage'

export const history = createBrowserHistory();

const App = () => {
  return (
    <>
      <Compose components={[
        QuotationContextProvider,
      ]}>
        <ThemeProvider theme={theme}>
          <Header />
          <Container>
            <Router history={history}>
              <Switch>
                <Route path="/" exact />
                <Route path="/error" exact component={Error} />
                <Route path="/cotacao-assinada" exact component={SignedQuotation} />
                <Route path="/sucesso" exact component={Success} />
                <Route path="/cotacao-fechada" exact component={ClosedQuotation} />
                <Route path="/error-page" exact component={ErrorPage} />
                <Route path="/:hash" component={PriceCotation} />
              </Switch>
            </Router>
          </Container>
        </ThemeProvider>
      </Compose>
    </>
  );
}

export default App;
