import React from 'react';
import { AppBar, Toolbar, Typography } from '@material-ui/core'
import logo from '../../assets/images/logo.svg'
import { theme } from '../../theme/theme';


function Header() {
 return (
     <>
     <AppBar position="fixed" style={{ zIndex: 100,}} color="primary">
         <Toolbar>
             <div style={{ paddingRight: theme.spacing(2) }}>
                <img src={logo} alt="logo cotação digital" />
             </div>
            <Typography variant="h6">Cotação Digital</Typography>
         </Toolbar>
     </AppBar>
     <Toolbar />
     </>
 )
}

export default Header