import { theme } from './../../theme/theme';
import { Box, BoxProps, styled, Typography, TypographyProps } from '@material-ui/core';
import { SentimentDissatisfiedOutlined } from '@material-ui/icons';

export const ContainerError = styled(Box)<BoxProps>({
    height: 'calc(80vh - 55px)',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(4),
    color: theme.palette.text.secondary,
})

export const ContentError = styled(Box)<BoxProps>({
    maxWidth: 482,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
    }
})

export const ContentMessage = styled(Box)<BoxProps>({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1)
})

export const Title = styled(Typography)<TypographyProps>({
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
    }
})

export const Subtitle = styled(Typography)<TypographyProps>({
    fontSize: theme.spacing(1.5),
    fontWeight: 'bold',
    textAlign: 'justify',
})

export const SadIcon = styled(SentimentDissatisfiedOutlined)({
    fontSize: theme.spacing(8),
})