import { Button, Typography } from "@material-ui/core"
import { useContext } from "react"
import { useParams } from "react-router-dom"
import ModalCustom from "../../components/ModalCustom"
import { QuotationContext } from "../../contexts/QuotationContextProvider"
import { ModalFooter } from './styles'

type ModalSubmitProps = {
    open: boolean,
    handleClose: () => void
}

function ModalSubmit({ open, handleClose }: ModalSubmitProps) {
    const { setProductQuotationData, setLoading, GetTableData } = useContext(QuotationContext)
    const { hash } = useParams<{ hash: string }>()

    return (
        <ModalCustom open={open} onClose={handleClose}>
            <>
                <Typography>Deseja limpar os valores da cotação?</Typography>
                <ModalFooter>
                    <Button color="default" onClick={handleClose}>Cancelar</Button>
                    <Button color="primary" onClick={() => {
                        localStorage.removeItem(hash)
                        setProductQuotationData([])
                        GetTableData(hash).then(response => {
                            setProductQuotationData(response)
                            setLoading(false)
                        });
                        handleClose()
                    }}>Confirmar</Button>
                </ModalFooter>
            </>
        </ModalCustom>
    )
}

export default ModalSubmit