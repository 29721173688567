import { Box, BoxProps, styled, Typography, TypographyProps } from '@material-ui/core'
import { CheckCircle } from '@material-ui/icons'
import { theme } from '../../theme/theme'

export const Content = styled(Box)<BoxProps>({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(2),
    padding: theme.spacing(8),
    margin: 'auto',
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
    }
})

export const Title = styled(Typography)<TypographyProps>({
    color: theme.palette.text.secondary,
    fontWeight: 'bold',
    textAlign: 'center',
})

export const ListText = styled(Typography)<TypographyProps>({
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
})

export const Card = styled(Box)<BoxProps>({
    background: theme.palette.background.paper,
    padding: theme.spacing(4),
    borderRadius: 4,
    boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.12)',
    width: '100%',
    maxWidth: 576,
    position: 'relative',
})

export const ListDiv = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
})

export const Div = styled('div')({
    display: 'flex',
    gap: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
})

export const ListItemCustom = styled('li')({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
})
export const DoneIcon = styled(CheckCircle)({
    fontSize: theme.spacing(5), position: 'absolute',
    right: '50%',
    left: '50%',
    transform: 'translate(-30%, 30%)',
    color: 'var(--green)',
})

export const PriceDiv = styled('div')({
    paddingLeft: 16
})

export const ListTextDescription = styled(Typography)<TypographyProps>({
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
})