import { Button, Typography, TextField, Snackbar } from "@material-ui/core"
import { Alert } from "@material-ui/lab"
import { useContext, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import api from "../../api/api"
import ModalCustom from "../../components/ModalCustom"
import { QuotationContext } from "../../contexts/QuotationContextProvider"
import { ModalFooter } from './styles'

type ModalSubmitProps = {
    open: boolean,
    handleClose: () => void
}

function ModalSubmit({ open, handleClose }: ModalSubmitProps) {
    const { tableData, nome, setNome, telefone, setTelefone, productQuotationData, setIsSubmit } = useContext(QuotationContext)
    const [snackbar, setSnackbar] = useState(false)
    const [snackbarSuccess, setSnackbarSuccess] = useState(false)
    const [required, setRequired] = useState(false)
    const history = useHistory()
    const { hash } = useParams<{ hash: string }>()
    var checkProduct = false

    const handleCloseSnackbar = () => setSnackbar(false)
    const handleCloseSnackbarSuccess = () => setSnackbarSuccess(false)

    const maskTelephone = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(?:(^\+\d{2})?)(?:([1-9]{2})|([0-9]{3})?)(\d{4,5})(\d{4})/,
                (fullMatch, country, ddd, dddWithZero, prefixTel, suffixTel) => {
                    if (country) return `${country} (${ddd || dddWithZero}) ${prefixTel}-${suffixTel}`;
                    if (ddd || dddWithZero) return `(${ddd || dddWithZero}) ${prefixTel}-${suffixTel}`;
                    if (prefixTel && suffixTel) return `${prefixTel}-${suffixTel}`;
                    return value;
                })
    }

    const cotacao = {
        codCotacao: tableData?.codInterno,
        codFornecedor: tableData?.codFornecedor,
        fornecedorTelefone: telefone,
        nomePreenchido: nome,
        produtos: productQuotationData?.map(p => ({
            codProduto: p.codProduto,
            preco: p.price,
            quantidade: p.quantidade
        }))
    }

    const handleVerificateProducts = () => {
        setIsSubmit(true)
        cotacao.produtos?.some((product) => {
            if (product.preco === undefined || product.preco < 0) {
                checkProduct = false
                setSnackbar(true)
                handleClose()
                return true
            }
            else {
                checkProduct = true
                return false
            }
        })

        nome && telefone && checkProduct ? api.post('/api/v1/CotacaoDigital', cotacao)
            .then(() => {
                setSnackbarSuccess(true)
                localStorage.removeItem(hash)
                history.push('/sucesso')
                handleClose()
            })
            :
            setRequired(true)
    }

    return (
        <>
            <ModalCustom open={open} onClose={handleClose}>
                <>
                    <Typography>Preencha os dados para enviar a cotação</Typography>
                    <TextField
                        helperText={required && 'Preencha o nome'}
                        error={required}
                        variant="standard"
                        label="Nome"
                        value={nome}
                        onChange={event => setNome(event.target.value)} />
                    <TextField
                        error={required}
                        helperText={required && 'Preencha o telefone'}
                        inputProps={{
                            maxLength: 15,
                        }}
                        variant="standard"
                        label="Telefone"
                        value={telefone}
                        onChange={event => setTelefone(maskTelephone(event.target.value))} />
                    <ModalFooter>
                        <Button color="default" onClick={handleClose}>Cancelar</Button>
                        <Button color="primary" onClick={handleVerificateProducts}>Confirmar</Button>
                    </ModalFooter>
                </>
            </ModalCustom>
            <Snackbar
                open={snackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="warning">Preencha corretamente todos os dados antes de enviar a cotação.</Alert>
            </Snackbar>
            <Snackbar
                open={snackbarSuccess}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbarSuccess} severity="success">Cotação enviada com sucesso.</Alert>
            </Snackbar>
        </>
    )
}

export default ModalSubmit