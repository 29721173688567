import { TextField, TextFieldProps } from "@material-ui/core";

type InputProps = TextFieldProps

function InputTableCustom({ ...props }: InputProps) {
    return (
        <TextField
            size="small"
            variant="outlined"
            {...props} />
    )
}

export default InputTableCustom