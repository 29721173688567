import { Modal, ModalProps } from "@material-ui/core";
import { ModalContainer } from "./styles";

type ModalCustomProps = ModalProps

function ModalCustom({ ...props }: ModalCustomProps) {
    return (
        <Modal
            {...props}
        >
            <ModalContainer>
                {props.children}
            </ModalContainer>
        </Modal>
    )
}

export default ModalCustom