import { Container, ContainerProps, styled } from "@material-ui/core";
import { theme } from "../../theme/theme";

export const CustomContainer = styled(Container)<ContainerProps>({
    background: theme.palette.background.paper,
    marginTop: theme.spacing(2),
    padding: `32px 72px`,
    flex: 1,
    height: '100%',
    minHeight: 'calc(100vh - 80px)',
    [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(2),
    },
    [theme.breakpoints.down('md')]: {
        margin: '0 auto',
    },
    boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.12)',
})