import { BoxProps, InputProps } from '@material-ui/core';
import { Box, styled } from '@material-ui/core';
import InputTableCustom from '../InputTableCustom'


export const LoadingContainer = styled(Box)<BoxProps>({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '250px',
});

export const InputTable = styled(InputTableCustom)<InputProps>({
    width: 120,
})