import { useLocation } from 'react-router';
import FooterLinks from '../../components/FooterLinks';
import { ContainerError, ContentError, ContentMessage, Title, Subtitle, SadIcon } from './styles'

type ErrorProps = {
    success: boolean
    data: any
    notifications: string[] | NotificationsProps[]
}

type NotificationsProps = {
    mensagem: string, 
    codErro: number
}

function Error() {
    const { state  } = useLocation<ErrorProps>();
    return (
        <>
        <ContainerError>
            <ContentError>
                <SadIcon />
                <ContentMessage>
                    <Title>Ops!</Title>
                    <Subtitle>Ocorreu um problema com a cotação. Por favor entre em contato com a empresa para maiores informações.</Subtitle>
                    {state.notifications.map((message, index) => <Subtitle key={index}>Causa: {typeof message === "object" ? message.mensagem : message}</Subtitle> )
                    }
                </ContentMessage>
            </ContentError>
        </ContainerError>
        <FooterLinks/>
        </>
    )
}

export default Error