import NumberFormat from "react-number-format";

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<any> | null) => void;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            fixedDecimalScale
            allowNegative={false}
            decimalScale={2}
            decimalSeparator={","}
            isNumericString
        />
    );
}