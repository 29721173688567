import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useState } from "react"
import api from "../api/api"
import { TableData, ProductQuotationData } from '../types/types'
import { history } from '../App'
import { IdentifySubdomain } from 'insidesistemas-error-pages/dist/pages/IdentifySubdomain'

type ContextType = {
    tableData: TableData | undefined
    setTableData: Dispatch<SetStateAction<TableData | undefined>>
    productQuotationData: ProductQuotationData[]
    setProductQuotationData: Dispatch<SetStateAction<ProductQuotationData[]>>
    loading: boolean
    setLoading: Dispatch<SetStateAction<boolean>>
    nome: string | undefined,
    setNome: Dispatch<SetStateAction<string>>,
    telefone: string | undefined,
    setTelefone: Dispatch<SetStateAction<string>>,
    GetTableData: (hash: string) => Promise<ProductQuotationData[]>,
    setIsSubmit: Dispatch<SetStateAction<boolean>>,
    isSubmit: boolean
}

type ContextProviderProps = {
    children: ReactNode
}

export const QuotationContext = createContext({} as ContextType)

export function QuotationContextProvider(props: ContextProviderProps) {
    const [tableData, setTableData] = useState<TableData>();
    const [productQuotationData, setProductQuotationData] = useState<ProductQuotationData[]>([]);
    const [loading, setLoading] = useState(true)
    const [telefone, setTelefone] = useState<string>('')
    const [isSubmit, setIsSubmit] = useState(false)
    const [nome, setNome] = useState<string>('')

    const getIdSessao = async (url: string): Promise<string> => {
        try {
            let response = await IdentifySubdomain(url);
            return response.data.data.idSessao as string;
        } catch (error) {
            console.error('getIdSessao:', error)
            return ''
        }
    }

    const GetTableData = useCallback(async (hash: string) => {
        const idSessao = await getIdSessao(process.env.REACT_APP_API_HUBBY as string)

        if (idSessao) {
            localStorage.setItem('id-sessao', idSessao);
            api.defaults.headers = { 'id-sessao': idSessao }
        } else {
            const error = {
                notifications: [ "Não foi possível obter uma sessão válida, tente abrir o link novamente em alguns instantes" ]
            }
            history.push('/error', error);
            return [];
        }

        let data: TableData = (await (api.get(`/api/v1/CotacaoDigital?hash=${encodeURIComponent(hash)}`))).data
        const produtos = JSON.parse(localStorage.getItem(hash)!);
        
        if (produtos !== null) {
            data = {
                ...data,
                produtos: produtos
            }
        }

        setTableData(data)

        return data.produtos
    }, [])

    return (
        <QuotationContext.Provider value={{
            tableData,
            setTableData,
            productQuotationData,
            setProductQuotationData,
            loading,
            setLoading,
            nome,
            setNome,
            telefone,
            setTelefone,
            GetTableData,
            isSubmit,
            setIsSubmit
        }}>
            {props.children}
        </QuotationContext.Provider>
    )
}