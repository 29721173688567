import { Container, Img, Progress } from "./styles"
import logo from '../../assets/images/logo.svg'

function Index() {
    return (
        <>
            <Container color="primary">
                <Img src={logo} alt="Logo do Cotação Digital"/>
                <Progress />
            </Container>
        </>
    )
}

export default Index
