import styled from "styled-components";

export const FooterContainer = styled.footer`
  background-color: #fafafa;
  border-top: 1px solid rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem 1rem 0rem 1rem;
  width: 100%;
  height: 3rem;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  a.logo {
    img {
      width: 4rem;
    }
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 60px;
  }

  @media (max-width: 600px) {
    width: 100%;

    a.logo {
      img {
        width: 8rem;
      }
    }
    div {
      margin-right: 4rem;

      a {
        img {
          width: 2rem;
          margin: 4px;
        }
      }
    }
  }
`;
