import { Box, styled, Container, ContainerProps, BoxProps } from "@material-ui/core";
import { theme } from "../../theme/theme";

export const CustomDiv = styled('div')({
    marginBottom: theme.spacing(4),
})

export const CustomBox = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
});

export const FooterContainer = styled(Container)<ContainerProps>({
    display: 'flex',
    position: 'fixed',
    padding: 0,
    bottom: 0,
    left: 0,
    right: 0,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: theme.palette.background.paper,
    marginTop: '52px,'
});

export const ButtonContainer = styled(Box)<BoxProps>({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: 52,
    gap: theme.spacing(2),
    paddingRight: theme.spacing(9),
    paddingLeft: theme.spacing(9),
    [theme.breakpoints.down('xs')]: {
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
});

export const ModalFooter = styled(Box)<BoxProps>({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
})