import { InputAdornment, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { InputTable } from "./styles";
import { NumberFormatCustom } from "../InputFormatCustom";
import { ReplaceNumberFormat } from '../../utils/utils';
import { ProductQuotationData } from '../../types/types';
import { QuotationContext } from "../../contexts/QuotationContextProvider";
import { useParams } from "react-router-dom";


function TablePriceQuotation() {
    const { productQuotationData, setProductQuotationData, isSubmit } = useContext(QuotationContext)
    const { hash } = useParams<{ hash: string }>()
    const [, setPrice] = useState(0)

    const handleChangePrice = React.useCallback((index: number, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, arr: ProductQuotationData[]) => {
        setPrice(Number(ReplaceNumberFormat(event.currentTarget.value)))
        arr[index].price = Number(ReplaceNumberFormat(event.currentTarget.value))
        localStorage.setItem(hash, JSON.stringify(arr))
        setProductQuotationData(arr)
    }, [hash, setProductQuotationData])

    return (
        <>

            <TableContainer>
                <Table style={{ marginBottom: '112px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Produto</TableCell>
                            <TableCell align="right">Quantidade</TableCell>
                            <TableCell align="right">Preço</TableCell>
                            <TableCell align="right">Subtotal</TableCell>
                            <TableCell>UN/Med</TableCell>
                            <TableCell>Descrição</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productQuotationData.map((product, index, arr) => (
                            <TableRow key={`table-row-${index}`}>
                                <TableCell key={`table-cell-codProduto-${index}`}>{product.codProduto}</TableCell>
                                <TableCell key={`table-cell-Descricao-${index}`}>{product.descricao}</TableCell>
                                <TableCell key={`table-cell-Quantidade-${index}`} align="right">{product.quantidade}</TableCell>
                                <TableCell key={`table-cell-Input-${index}`} align="right">
                                    <InputTable
                                        error={(product.price < 0 || product.price == null) && isSubmit}
                                        helperText={(product.price < 0 || product.price == null) && isSubmit ? "Preço inválido" : ''}
                                        InputProps={{
                                            inputComponent: NumberFormatCustom as any,
                                            startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                                        }}
                                        name={`input-price-${index}`}
                                        key={`input-price-${index}`}
                                        id={`input-${index}`}
                                        onChange={event => handleChangePrice(index, event, arr)}
                                        defaultValue={product.price} />
                                </TableCell>
                                <TableCell key={`table-cell-Subtotal-${index}`} align="right" width={150}>{
                                    product.price !== undefined ?
                                        (product.price * product.quantidade).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
                                        :
                                        product.price
                                }</TableCell>
                                <TableCell key={`table-cell-Unidade-${index}`}>{product.unidade}</TableCell>
                                <TableCell key={`table-cell-Observacao-${index}`} >{product.observacao}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </>
    )
}

export default TablePriceQuotation;