import axios from 'axios';
import { history } from '../App'

const api = axios.create({
    baseURL: process.env.REACT_APP_API_HUBBY as string,
    headers: { 'id-sessao': localStorage.getItem('id-sessao') }
});

api.interceptors.response.use((response: any) => {
    if (!response.success) {
    }
    return response.data
}, (error) => {
    if (error.response === undefined || [500, 501, 502].includes(error.response?.status as number)) {
        console.error(error)
        history.push('/error', { notifications: [ "Ocorreu um erro interno no Servidor" ] })
    }

    if (error.response?.status === 400) {
        history.push('/error', error.response.data)
    }
    if (error.response?.status === 410) {
        if (error.response.data.notifications[0].codErro === 1) history.push('/cotacao-fechada')
        if (error.response.data.notifications[0].codErro === 3) history.push('/error', error.response.data)
        else history.push('/cotacao-assinada')
    }
    return Promise.reject(error);
})

export default api