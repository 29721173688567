import { createTheme } from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#5B2A8C',
    },
    secondary: {
      main: '#F2B705',
    },
    background: {
      paper: '#ffffff'
    }
  },
});