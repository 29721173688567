import { Container, ContainerProps } from '@material-ui/core';
import { styled } from '@material-ui/core';

export const FooterContainer = styled(Container)<ContainerProps>({
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    marginTop: '52px,',
    marginBottom: '3rem'
})
