import { CustomContainer } from './styles'

type ContainerProps = {
    children: JSX.Element[]
}

function Container({ children }: ContainerProps) {
    return (
        <CustomContainer>
            {children}
        </CustomContainer>
    )
}

export default Container