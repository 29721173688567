import { LinearProgress, withStyles } from '@material-ui/core';
import { Box, BoxProps, styled } from '@material-ui/core';
import { theme } from '../../theme/theme';


export const Container = styled(Box)<BoxProps>({
    height: '100vh',
    width: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    background: theme.palette.primary.main,
    zIndex: 1000,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
});

export const Img = styled('img')({
    height: 72,
})

export const Progress = withStyles({
    root: {
        width: '100%',
        maxWidth: 300,
        borderRadius: 4,
    },
    colorPrimary: {
        backgroundColor: theme.palette.secondary.main,
    },
})(LinearProgress)