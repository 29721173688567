import { useContext, useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { ButtonContainer, CustomBox, CustomDiv } from "./styles";
import TablePriceQuotation from "../../components/TablePriceQuotation";
import Footer from "../../components/Footer";
import Container from "../../components/Container";
import ModalSubmit from "./ModalSubmit";
import ModalClear from "./ModalClear";
import { QuotationContext } from "../../contexts/QuotationContextProvider";
import { useParams } from "react-router-dom";
import SplashScreen from "../../components/SplashScreen";
import moment from "moment";
import FooterLinks from "../../components/FooterLinks";

function PriceCotation() {
  const {
    tableData,
    setProductQuotationData,
    setLoading,
    GetTableData,
    loading,
    setIsSubmit,
  } = useContext(QuotationContext);
  const [openModalSubmit, setOpenModalSubmit] = useState(false);
  const [openModalCLear, setOpenModalCLear] = useState(false);
  const { hash } = useParams<{ hash: string }>();
  const handleOpenModalSubmit = () => setOpenModalSubmit(true);
  const handleCloseModalSubmit = () => setOpenModalSubmit(false);
  const handleOpenModalCLear = () => setOpenModalCLear(true);
  const handleCloseModalCLear = () => {
    setIsSubmit(false);
    setOpenModalCLear(false);
  };

  const getDayValidity = () => {
    return Math.ceil(
      moment(tableData?.data)
        .add(tableData?.vigencia, "days")
        .diff(moment(), "days", true)
    );
  };

  useEffect(() => {
    const GetData = async () => {
      const response = await GetTableData(hash);
      setProductQuotationData(response);
      setLoading(false);
    };

    GetData();
  }, [hash, GetTableData, setLoading, setProductQuotationData]);

  return (
    <>
      {loading ? (
        <SplashScreen />
      ) : (
        <>
          <Container>
            <CustomBox>
              <Box>
                <CustomDiv>
                  <Typography variant="h6">
                    {tableData?.nomeFornecedor}
                  </Typography>
                </CustomDiv>
                <CustomDiv>
                  {tableData?.vigencia! > 0 && (
                    <Typography variant="subtitle1">
                      Vigência da Cotação: {tableData?.vigencia} dias - Restam{" "}
                      {getDayValidity()} dias
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    Forma de Pagamento: {tableData?.formaPagamento}
                  </Typography>
                  <Typography variant="subtitle1">
                    Frete por conta:{" "}
                    {Number(tableData?.tipoFrete) === 1
                      ? "Emitente"
                      : Number(tableData?.tipoFrete) === 2
                      ? "Destinatário"
                      : (null as any)}
                  </Typography>
                  <Typography variant="subtitle1">
                    Valor Frete:{" "}
                    {tableData?.frete.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </Typography>
                  {tableData?.nomeTransportadora && (
                    <Typography variant="subtitle1">
                      Transportadora: {tableData?.nomeTransportadora}
                    </Typography>
                  )}
                  <Typography variant="subtitle1">
                    Prazo para Entrega: {tableData?.diasParaEntrega} dias
                  </Typography>
                </CustomDiv>
              </Box>
            </CustomBox>
            <TablePriceQuotation />
            <Footer>
              <ButtonContainer>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleOpenModalCLear}
                >
                  Limpar
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Send />}
                  onClick={handleOpenModalSubmit}
                >
                  Enviar
                </Button>
              </ButtonContainer>
            </Footer>
          </Container>
          <FooterLinks />
          <ModalSubmit
            open={openModalSubmit}
            handleClose={handleCloseModalSubmit}
          />
          <ModalClear
            open={openModalCLear}
            handleClose={handleCloseModalCLear}
          />
        </>
      )}
    </>
  );
}

export default PriceCotation;
