import { ContainerError, ContentError, ContentMessage, Title, Subtitle, WarningIcon } from './styles'

function Index() {
    return (
        <ContainerError>
            <ContentError>
                <WarningIcon />
                <ContentMessage>
                    <Title>Atenção!</Title>
                    <Subtitle>Esta cotação encontra-se encerrada.</Subtitle>
                </ContentMessage>
            </ContentError>
        </ContainerError>
    )
}

export default Index
