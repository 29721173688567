import { FooterContainer } from './styles'

type FooterProps = {
    children: JSX.Element[] | JSX.Element | string | string[]
}

function Footer({ children }: FooterProps) {
    return (
        <FooterContainer>
            {children}
        </FooterContainer>
    )
}

export default Footer;