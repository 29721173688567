import { ContainerError, ContentError, ContentMessage, Title, Subtitle, SmileIcon } from './styles'

function SignedQuotation() {
    return (
        <ContainerError>
            <ContentError>
                <SmileIcon />
                <ContentMessage>
                    <Title>Oba!</Title>
                    <Subtitle>A sua cotação já foi assinada e enviada para o cliente, aguarde contato.</Subtitle>
                </ContentMessage>
            </ContentError>
        </ContainerError>
    )
}

export default SignedQuotation