import { FooterContainer} from './styles'
import logo from '../../assets/images/logo.png'
import face from '../../assets/images/facebook-brands.svg'
import insta from '../../assets/images/instagram-brands.svg'
import whats from '../../assets/images/whatsapp-brands.svg'


function FooterLinks() {
    return (
        <FooterContainer>
                <a className="logo" href="https://www.insidesistemas.com.br/" target="_blank" rel="noreferrer">
                    <img src={logo} alt="logo inside sistemas" />
                </a>
                <div>
                    <a href="https://api.whatsapp.com/send?phone=554591351243" target="_blank" rel="noreferrer">
                        <img src={whats} alt="contato whatsapp" />
                    </a>
                    <a href="https://www.facebook.com/InsideSistemas/" target="_blank" rel="noreferrer">
                        <img src={face} alt="facebook inside sistemas" />
                    </a>
                    <a href="https://www.instagram.com/insidesistemas/" target="_blank" rel="noreferrer">
                        <img src={insta} alt="instagram inside sistemas" />
                    </a>
                </div>
        </FooterContainer>
    )
}

export default FooterLinks;